<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import NewListingTable from "@/components/listings/new-listing-table";
import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";
// import SelectGroup from "@/components/forms/select-group";
// import InputGroup2 from "@/components/forms/input-group";

export default {
    components: {
        Layout,
        PageHeader,
        NewListingTable,
        InputGroup,
        SelectGroup
    },
    data() {
        return {
            title: "Payment Methods",
            items: [
                {
                    text: "Payment Methods"
                },
            ],
            fields: [
                {
                    key: 'id',
                    label: "ID",
                    sortable: true
                },
                {
                    key: 'type',
                    label: "Type",
                    sortable: false
                },
                {
                    key: 'name',
                    label: "Name",
                    sortable: false,
                },
                {
                    key: 'currency_code',
                    label: "Currency Code",
                    sortable: false,
                },
                {
                    key: 'description',
                    label: "Description",
                    sortable: false,
                },
                {
                    key: 'status',
                    label: "Status",
                    sortable: true,
                },
                { key: "action" },
            ],
            status_options: [
                {"text": "All", "value": ""},
                {"text": "Active", "value": 1},
                {"text": "Inactive", "value": 0},
            ],
            filter: {
                "status": "",
                "type": "",
                "name": "",
            },
            errors: {}
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
    },
    methods: {
        filterTable() {
            this.$refs.list_table.loadTable(1);
        },
        resetTable() {
            this.filter.status = '';
            this.filter.type = '';
            this.filter.name = '';
            this.$refs.list_table.loadTable(1);
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <b-card class="mb-2" style="border-radius: 15px;">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="type" name="type" v-model="filter.type" label="Type" placeholder="Payment Method Type"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <InputGroup id="name" name="name" v-model="filter.name" label="Name" placeholder="Payment Method Name"/>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <SelectGroup id="status" name="status" v-model="filter.status" label="Status" placeholder="Payment Method Status" :options="status_options" :value="parseInt(1)" :default_value="false">
                    </SelectGroup>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
                    <a href="javascript:void(0);" class="btn btn-orange" @click.once="filterTable">
                        Filter
                    </a>
                    <a href="javascript:void(0);" class="btn btn-filter" style="margin-left: 11px;" @click="resetTable">
                        Reset
                    </a>
                </div>
            </div>
            <!-- <InputGroup id="test" name="test" v-model="inputval" :error="errors.name" label="String" placeholder="test"/>

            <SelectGroup id="test" name="test" v-model="inputval2" :error="errors.name" label="String" placeholder="test" :options="options"/> -->
            <!-- <SelectGroup id="test" name="test" v-model="inputval2" :error="errors.name" label="String" placeholder="test" :options="options"/> -->
            <!-- <button @click="test">test</button> -->
        <!-- </b-card>
        <b-card> -->
            <NewListingTable :entries="parseInt(10)" :filter="filter" :fields="fields" path="setting/payment-method" ref="list_table">
                <template v-slot:cell(action)="{ item }">
                    <router-link class="mr-3 grey-icon-color" :to="{name: 'Payment Methods Edit', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                </template>
            </NewListingTable>
        </b-card>
    </Layout>
</template>